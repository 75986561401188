.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 table th {
  background: var(--bs-primary) !important;
  color: white !important;
 }
 .nx-confirm-button-ok{
  background: var(--bs-primary) !important;
  color: white !important;
 }
 .notiflix-confirm-head h5 {
  color:var(--bs-primary) !important;
 }

 fieldset, legend {
  all: revert;
  border-radius: 5px;
  border: 1px solid var(--bs-primary);
  padding: 10px;
  margin: 5px;
}
legend {
  font-size: 1.2em;
  background-color: var(--bs-primary);
  color: white;
  padding: 5px 10px;
}

.hvr-shutter-out-horizontal:hover{
  color: white !important;
}

/* customize ::placeholder */
input::placeholder {
  color:#cccc!important;
}

.wd-10{
  width: 10px!important;
}
.wd-100{
  width: 100px!important;
}
.wd-200{
  width: 200px!important;
}
.wd-300{
  width: 300px!important;
}
.wd-400{
  width: 400px!important;
}
.wd-500{
  width: 500px!important;
}
.wd-600{
  width: 600px!important;
}
