.card{
    background-color: #fff;
    border-radius: 7px;
    .card-body{
        background-color: #fff;
    }
}

.remove-sub-image{
   position: relative;
   margin-bottom: 100px!important;
   margin-left: -12px;
}